import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { MainListItems, SecondaryListItems } from "./listItems";
import {
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import toast from "toasted-notes";

import Title from "./Title";

import icon from "../../../assets/images/logo.png";
import { Reports } from "../Reports/Reports";
import { ReportsDay } from "../Reports/ReportsDay";
import { Goals } from "../Goals/Goals";
import { useAuthState } from "../../../context/auth";
import { ReportsR } from "../Regional/Reports/ReportsR";
import { ReportsRDay } from "../Regional/Reports/ReportsRDay";
import { GoalsR } from "../Regional/Goals/GoalsR";
import { Audit } from "../Audit/Audit";
import { Clients } from "../Clients/Clients";
import { Award } from "../Award/Award";
import { VendorTarget } from "../VendorTarget/VendorTarget";
import { Vendor } from "../Vendor/Vendor";
import { Images } from "../../Images";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.celuweb.com/">
        CELUWEB.COM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;
const primaryColor = getComputedStyle(
  document.documentElement
).getPropertyValue("--color_primario"); //"#0075bb";
const letterColor = getComputedStyle(document.documentElement).getPropertyValue(
  "--color_letra"
); //"#fff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: letterColor,
  },
  label: {
    color: letterColor,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    background: primaryColor,
    color: letterColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: primaryColor,
    color: letterColor,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    background: primaryColor,
    color: letterColor,
    flexGrow: 1,
  },
  drawerPaper: {
    background: primaryColor,
    position: "relative",
    whiteSpace: "nowrap",
    color: letterColor,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    color: letterColor,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    color: letterColor,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
  },
  fixedHeightMin: {
    height: 180,
  },
  notify: {
    color: letterColor,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  demo: {
    backgroundColor: primaryColor,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: primaryColor,
    color: letterColor,
  },
  mainListItems: {
    backgroundColor: primaryColor,
    color: letterColor,
    span: {
      color: letterColor,
    },
  },
  divider: {
    backgroundColor: primaryColor,
  },
  iconButton: {
    color: letterColor,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [route, setRoute] = React.useState(8);

  const [count, setCount] = React.useState(0);
  const [form, setForm] = React.useState(false);

  const { user } = useAuthState();
  console.log(user);

  useEffect(() => {
    Notification.requestPermission().then(function (result) {
      console.log(result);
    });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRoute = (route) => {
    setRoute(route);
  };

  const widthChange = () => {
    if (window.innerWidth <= 480) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", widthChange);
    return () => {
      window.removeEventListener("resize", widthChange);
    };
  });

  useEffect(() => {
    if (document.documentElement.clientWidth <= 480) {
      widthChange();
    }
  }, []);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={
          // "header" +
          clsx(classes.appBar, open && classes.appBarShift)
        }
        //className="header"
      >
        <Toolbar className={classes.toolbar} id="toolbar-display">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h2"
            variant="h6"
            //style={{ color: "#0075bb" }}
            noWrap
            className={classes.title}
          >
            {(route === 0 && "Dashboard") ||
              (route === 1 && "Reporte Díario") ||
              (route === 2 && "Objetivos") ||
              (route === 3 && "Clientes Pareto") ||
              (route === 4 && "Premiación") ||
              (route === 5 && "Auditoria") ||
              (route === 6 && "Objetivo Vendedor") ||
              (route === 7 && "Vendedor") ||
              (route === 8 && "Imagenes")}
          </Typography>

          {/* <img src={logo} width="100" height="30" style={{ margin: "1em" }} /> */}
          <Typography
            component="h2"
            variant="h6"
            color="initial"
            noWrap
            style={{ margin: "0.5em" }}
          >
            {user.regional ? user.username : "Admin"}
          </Typography>
          {/* <IconButton
            color="inherit"
            onClick={() => {
              setCount(0);
              setRoute(0);
            }}
          >
            <Badge badgeContent={count} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <h3 className="toolbar-title">POSTOBON</h3>
          {/* <h4>Emart</h4> */}
          <IconButton
            className={classes.iconButton}
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          {
            <MainListItems
              className={classes.mainListItems}
              handleRoute={handleRoute}
            />
          }
        </List>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          {<SecondaryListItems open={open} />}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="xl" className={classes.container}>
        {document.documentElement.clientWidth <= 480 ? (
        <h1>
        <Typography
            component="h2"
            variant="h6"
            style={{ color: "#0075bb" }}
          >
            {(route === 0 && "Dashboard") ||
              (route === 1 && "Reporte Díario") ||
              (route === 2 && "Objetivos") ||
              (route === 3 && "Clientes Pareto") ||
              (route === 4 && "Premiación") ||
              (route === 5 && "Auditoria") ||
              (route === 6 && "Objetivo Vendedor") ||
              (route === 7 && "Vendedor") ||
              (route === 8 && "Imagenes")}
          </Typography>
        </h1>
      ) : null}
          {/* <Title>Ventas del Día</Title> */}
          {user.regional === null ? (
            <Grid container spacing={3}>
              {route === 0 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Reports />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 1 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <ReportsDay />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 2 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Goals />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 3 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Clients />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 4 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Award />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 5 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Audit />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 6 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <VendorTarget />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 7 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Vendor />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 8 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Images />
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {route === 0 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <ReportsR />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 1 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <ReportsRDay />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 2 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <GoalsR />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 3 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Clients />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 4 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Award />
                    </Paper>
                  </Grid>
                </>
              )}
              {route === 8 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Images />
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
